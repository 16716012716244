import { Tooltip, TooltipProps, Typography, TypographyProps, useTheme } from '@mui/material';
import { memo, ReactNode, useMemo, useRef } from 'react';
import _ from 'lodash';

type TTruncatedTextProps = {
  text: string | ReactNode; // We accept react node primarily for tables empty strings, which are represented by an icon
  ellipsisPosition?: 'start' | 'end';
  tooltipProps?: Omit<TooltipProps, 'children'>;
  lineClamp?: number | string;
  // Primarily used for filters on tables
  children?: ReactNode;
  isTooltipHidden?: boolean;
} & TypographyProps;

function TruncatedText({
  text,
  ellipsisPosition = 'end',
  tooltipProps,
  lineClamp,
  children,
  isTooltipHidden,
  ...otherProps
}: TTruncatedTextProps) {
  const { palette } = useTheme();
  const textRef = useRef<HTMLParagraphElement>(null);

  const otherPropsWithoutSx = useMemo(() => _.omit(otherProps, 'sx'), [otherProps]);

  return (
    <Tooltip
      title={isTooltipHidden ? undefined : text}
      placement="left"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            background: palette.secondary[600],
            width: '500px',
            maxHeight: '50vh',
            overflowY: 'auto',
            overflowX: 'hidden'
          }
        }
      }}
      enterDelay={1000}
      enterNextDelay={1000}
      {...tooltipProps}
    >
      <Typography
        ref={textRef}
        overflow="hidden"
        textOverflow="ellipsis"
        dir={ellipsisPosition === 'start' ? 'rtl' : 'ltr'}
        sx={{
          // necessary to correctly handle both multiline and one line ellipsis
          display: lineClamp ? '-webkit-box' : 'block',
          WebkitLineClamp: lineClamp ? lineClamp.toString() : undefined,
          WebkitBoxOrient: lineClamp ? 'vertical' : undefined,
          wordBreak: 'break-all',
          whiteSpace: lineClamp ? 'normal' : 'nowrap',
          ...otherProps.sx
        }}
        {...otherPropsWithoutSx}
      >
        {/* Used &lrm so that the rtl direction does not change the position of our punctuation */}
        {text}&lrm;
        {children}
      </Typography>
    </Tooltip>
  );
}

export default memo(TruncatedText);
