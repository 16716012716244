import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { config } from 'src/config';
import { jobKeys } from './keys';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';
import { JobUpdateSettingsRequest } from '@arcanna/models/Jobs/JobUpdateSettingsRequest';
import { Serializer } from '@arcanna/utils';

type TUseJobSettingsSave = {
  jobId: number;
};

function useJobSettingsSave({ jobId }: TUseJobSettingsSave) {
  const { t } = useTranslation(['requests']);
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.job.updateSettings(jobId), [jobId]);
  const axiosFunction = useCallback((body: object) => axios.post<ResourceWrapper<ResponseCommon>>(URL, body), [URL]);

  // QUERY
  return useMutation(
    jobKeys.updateSetings(jobId),
    (payload: JobUpdateSettingsRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);

      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async (_, jobId) => {
        await queryClient.invalidateQueries([config.api.jobInfo.replace(':id', String(jobId))]);

        showSuccessNotification(
          t('requests:job.updateSettings.success.title'),
          t('requests:job.updateSettings.success.subtitle')
        );
      },
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
        if (error.data?.resource?.request?.reason) {
          showErrorNotification(t('requests:job.updateSettings.error.title'), error.data.resource.request.reason);
        } else {
          showErrorNotification(t('requests:job.updateSettings.error.title'), t('requests:job.updateSettings.error.subtitle'));
        }
      }
    }
  );
}

export default useJobSettingsSave;
