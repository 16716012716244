import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { JobStateEnum } from './JobStateEnum';

export enum JobActivityLogType {
  CreateJob = 'CREATE_JOB',
  Retrain = 'RETRAIN',
  TableColumnSelection = 'TABLE_SELECTION',
  CustomFieldCreate = 'CUSTOM_FIELD_CREATE',
  CustomFieldUpdate = 'CUSTOM_FIELD_UPDATE',
  JobStateUpdate = 'UPDATE_JOB_STATE',
  JobIntegrationUpdated = 'JOB_INTEGRATION_UPDATED',
  JobIntegrationAdded = 'JOB_INTEGRATION_ADDED',
  JobIntegrationRemoved = 'JOB_INTEGRATION_REMOVED',
  JobContextEnrichmentUpdated = 'JOB_CE_UPDATED',
  JobContextEnrichmentAdded = 'JOB_CE_ADDED',
  JobContextEnrichmentRemoved = 'JOB_CE_REMOVED',
  FeaturesUpdate = 'FEATURES_UPDATE',
  AutomaticRetrainSkipped = 'AUTOMATIC_RETRAIN_SKIPPED',
  IntegrationOrderUpdate = 'INTEGRATION_ORDER_UPDATE'
}

@JsonObject('JobActivityLog')
export class JobActivityLog {
  @JsonProperty('id', String)
  id: string;

  @JsonProperty('action', String)
  action: JobActivityLogType;

  @JsonProperty('username', String)
  username: string;

  @JsonProperty('created_at', String)
  createdAt: string;

  @JsonProperty('features', String)
  features: string | null;

  @JsonProperty('integration_title', String)
  integrationTitle: string | null;

  @JsonProperty('integration_id', Number)
  integrationId: number | null;

  @JsonProperty('state', String)
  state: JobStateEnum | null;

  @JsonProperty('columns', String)
  columns: string | null;

  @JsonProperty('field_name', String)
  fieldName: string | null;
}

@JsonObject('JobActivityLogResponse')
export class JobActivityLogResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('entries', [JobActivityLog])
  entries: JobActivityLog[];
}
