import { FormProvider, RHFSmartGeneratedField, RHFSubmitButton, RHFTextField } from '@arcanna/forms';
import { joiResolver } from '@hookform/resolvers/joi';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { getFormDefaultValues, getFormSchema } from './IntegrationForm.utils';
import { IntegrationJobParameterRecord } from 'src/components/shared/models/integration/job/IntegrationJobParameterRecord';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Stack } from '@mui/material';
import { IntegrationReadRecord } from 'src/components/shared/models/integration/IntegrationReadRecord';
import useFutureParametersValues from '@arcanna/pages/AddJob/components/IntegrationUpdateModal/IntegrationCreateModal/hooks/useFutureParametersValues';
import { Spinner } from '@arcanna/generic';
import { IntegrationSubcategoryRecord } from '../../../../components/shared/models/integration/subcategory/IntegrationSubcategoryRecord';
import { InlineNotification } from '../Notifications';
import { showErrorNotification } from '../../../../components/shared/utilities/notification';

type TIntegrationFormProps = {
  parameters: IntegrationJobParameterRecord[];
  onCancel: () => void;
  initialIntegration?: IntegrationReadRecord;
  onSubmit: (values: unknown) => Promise<void>;
  confirmBtnText?: string;
  closeBtnText?: string;
  actionButton?: ReactNode;
  integrationSubcategory?: IntegrationSubcategoryRecord;
  getRequestPayload: <Payload>(formValues: unknown) => Payload;
};

function IntegrationForm({
  parameters,
  onCancel,
  initialIntegration,
  onSubmit,
  confirmBtnText,
  closeBtnText,
  actionButton,
  integrationSubcategory,
  getRequestPayload
}: TIntegrationFormProps) {
  const { t } = useTranslation(['job-create', 'common', 'forms']);
  const { futureParameters, getFutureValues, isLoadingFutureValues } = useFutureParametersValues({
    // @ts-ignore
    subcategoryId: integrationSubcategory?.id,
    allParameters: parameters
  });

  const [futureParamsAreOutdated, setFutureParamsAreOutdated] = useState<boolean>(false);
  const nonFutureParameters = useMemo(() => parameters.filter((parameter) => !parameter.is_future), [parameters]);
  const schema = useMemo(
    () =>
      getFormSchema({
        parameters,
        t
      }),
    [parameters, t]
  );
  const defaultValues = useMemo(
    () =>
      getFormDefaultValues({
        // eslint-disable-next-line
        parameters,
        initialIntegration
      }),
    [parameters, initialIntegration]
  );

  const methods = useForm<object>({
    defaultValues: defaultValues,
    resolver: joiResolver(schema),
    shouldUnregister: true
  });

  const allFormValues = methods.watch();

  // @ts-ignore
  const nonFutureFormValues = useWatch({
    control: methods.control,
    name: nonFutureParameters.map((parameter) => parameter.field)
  });

  useEffect(
    function futureParamsOutdatedWhenNonFutureValuesChange() {
      if (futureParameters.length == 0) {
        return;
      }
      if (!methods.formState.isDirty) {
        return;
      }
      setFutureParamsAreOutdated(true);
    },
    // eslint-disable-next-line
    [nonFutureFormValues]
  );

  return (
    <FormProvider<object>
      methods={methods}
      onSubmit={(e) => {
        if (futureParamsAreOutdated) {
          e.preventDefault();
          showErrorNotification(t('common:error'), t('integration:futureParamsOutdatedError'));
        } else {
          methods.handleSubmit(onSubmit)(e);
        }
      }}
    >
      <Grid container columns={24} rowSpacing={1} columnSpacing={3}>
        <Grid item xs={24}>
          <RHFTextField
            dataTestId="add-integration-title"
            name="title"
            label={t('createIntegrationForm.title')}
            required
            fullWidth
          />
        </Grid>
        {nonFutureParameters.map((parameter) => (
          <RHFSmartGeneratedField.component key={parameter.field} parameter={parameter} />
        ))}
        {futureParameters.length > 0 ? (
          <Grid item xs={24}>
            <Button
              color={'primary'}
              variant={'contained'}
              endIcon={isLoadingFutureValues ? <Spinner /> : null}
              disabled={isLoadingFutureValues}
              onClick={async () => {
                const valuesNecessaryToGetFuture: Record<string, string> = nonFutureParameters.reduce((acc, parameter) => {
                  // @ts-ignore
                  acc[parameter.field] = allFormValues[parameter.field];
                  return acc;
                }, {});
                // @ts-ignore
                const isValid = await methods.trigger(Object.keys(valuesNecessaryToGetFuture));
                if (!isValid) {
                  return;
                }
                getFutureValues(valuesNecessaryToGetFuture, getRequestPayload, () => {
                  setFutureParamsAreOutdated(false);
                  const values = methods.getValues();
                  const resetFuture = futureParameters.reduce((acc, parameter) => {
                    // @ts-ignore
                    acc[parameter.field] = parameter.default;
                    return acc;
                  }, {});
                  methods.reset({ ...values, ...resetFuture });
                });
              }}
            >
              Connect
            </Button>
          </Grid>
        ) : null}
        {futureParamsAreOutdated && futureParameters.length > 0 ? (
          <Grid item xs={24}>
            <InlineNotification
              type={'warning'}
              title={'You need a fresh connection in order to get valid values for the following parameters'}
            />
          </Grid>
        ) : null}
        {futureParameters.map((parameter) => (
          <RHFSmartGeneratedField.component key={parameter.field} parameter={parameter} />
        ))}
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} marginTop={5}>
        <Stack direction="row" alignItems="center" gap={2}>
          <RHFSubmitButton text={confirmBtnText ?? t('common:confirm')} />
          <Button color="secondary" variant="tertiary" onClick={onCancel}>
            {closeBtnText ?? t('common:cancel')}
          </Button>
        </Stack>
        {actionButton}
      </Stack>
    </FormProvider>
  );
}

export default IntegrationForm;
